var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_page_index"},[_c('div',{staticClass:"page_head"},[_vm._m(0),_c('div',{staticClass:"full-in-box"},[_c('div',{staticClass:"full-in-top-line",on:{"click":function($event){return _vm.handleScore(3)}}},[(
            _vm.scoreHistory &&
              _vm.scoreHistory.score &&
              _vm.scoreHistory.score.predict_score || (_vm.scoreHistory &&
              _vm.scoreHistory.score &&_vm.scoreHistory.score.real_score)
          )?_c('span',{staticClass:"full-in-input f18"},[_c('span',{staticStyle:{"font-size":"40px","letter-spacing":"-4px","color":"#910000","font-weight":"500","font-family":"Bebas"}},[_vm._v(_vm._s(_vm.scoreHistory.score.predict_score?_vm.scoreHistory.score.predict_score:_vm.scoreHistory.score.real_score))]),_vm._v(" "+_vm._s(_vm.scoreHistory.score.course1)+"/"+_vm._s(_vm.scoreHistory.score.course2.replace(',', '/'))+" ")]):_c('span',{staticClass:"full-in-input f18"},[_vm._v("请输入模考/高考成绩")]),_c('i',{staticClass:"iconfont full-in-icon el-icon-edit"})]),_c('div',{staticClass:"full-in-btn-group"},[_c('button',{staticClass:"one-click-filling border-primary van-button van-button--danger van-button--normal van-button--plain van-button--round",on:{"click":function($event){return _vm.handleHome('我的志愿表', '/myTablesList')}}},[_vm._m(1)]),_c('button',{staticClass:"intelligent-filling van-button van-button--danger van-button--normal van-button--round",on:{"click":function($event){return _vm.handleScore(2)}}},[_vm._m(2)])])]),_c('div',{staticClass:"nav-box"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.handleClick(item)}}},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('span',[_vm._v(_vm._s(item.name))])])}),0),_vm._m(3),_c('div',{staticClass:"kx"},[_c('div',{staticClass:"l"},[_c('img',{attrs:{"src":require("../assets/image/index/img-zuixinzixun@2x.png")}}),_c('div',{staticClass:"kx-div"},[_c('van-notice-bar',{attrs:{"scrollable":false}},[_c('van-swipe',{staticClass:"notice-swipe",attrs:{"vertical":"","autoplay":3000,"show-indicators":false}},_vm._l((_vm.noticeList),function(item,index){return _c('van-swipe-item',{key:index},[_vm._v(" "+_vm._s(item.title))])}),1)],1)],1)]),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner_box"},[_c('img',{attrs:{"src":require("../assets/image/head_bg.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"van-button__content"},[_c('span',{staticClass:"van-button__text"},[_vm._v(" 我的志愿 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"van-button__content"},[_c('span',{staticClass:"van-button__text"},[_c('div',{staticClass:"intelligent-filling-icon"},[_c('img',{attrs:{"src":require("../assets/image/icon-tianbao.png")}})]),_vm._v(" 智能填报 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-list"},[_c('img',{attrs:{"src":require("../assets/image/index/img-yaoqinghaoyou@2x.png")}}),_c('img',{attrs:{"src":require("../assets/image/index/icon-shaitongzhishu@2x.png")}}),_c('img',{attrs:{"src":require("../assets/image/index/icon-xiangxijiaocheng@2x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"r"},[_c('img',{attrs:{"src":require("../assets/image/jinru.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0px 15px 70px"}},[_c('img',{staticClass:"swipe-img",staticStyle:{"border-radius":"10px","width":"100%"},attrs:{"src":require("../assets/image/index/ditu.png"),"alt":""}})])
}]

export { render, staticRenderFns }