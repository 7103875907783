<template>
  <div class="home_page_index">
    <div class="page_head">
      <div class="banner_box">
        <img src="../assets/image/head_bg.png" alt="" />
      </div>
      <div class="full-in-box">
        <div class="full-in-top-line" @click="handleScore(3)">
          <span
            class="full-in-input f18"
            v-if="
              scoreHistory &&
                scoreHistory.score &&
                scoreHistory.score.predict_score || (scoreHistory &&
                scoreHistory.score &&scoreHistory.score.real_score)
            "
          >
            <span
              style="font-size: 40px;letter-spacing: -4px;color:#910000;font-weight: 500;font-family: Bebas;"
              >{{ scoreHistory.score.predict_score?scoreHistory.score.predict_score:scoreHistory.score.real_score }}</span
            >
            {{ scoreHistory.score.course1 }}/{{
              scoreHistory.score.course2.replace(',', '/')
            }}
          </span>
          <span class="full-in-input f18" v-else>请输入模考/高考成绩</span>
          <i class="iconfont full-in-icon el-icon-edit"></i>
        </div>
        <div class="full-in-btn-group">
          <button
            class="one-click-filling border-primary van-button van-button--danger van-button--normal van-button--plain van-button--round"
            @click="handleHome('我的志愿表', '/myTablesList')"
          >
            <div class="van-button__content">
              <span class="van-button__text">
                我的志愿
              </span>
            </div>
          </button>
          <!-- <button
            class="one-click-filling border-primary van-button van-button--danger van-button--normal van-button--plain van-button--round"
            @click="handleScore(1)"
          >
            <div class="van-button__content">
              <span class="van-button__text">
                一键填报
              </span>
            </div>
          </button> -->
          <button
            class="intelligent-filling van-button van-button--danger van-button--normal van-button--round"
            @click="handleScore(2)"
          >
            <div class="van-button__content">
              <span class="van-button__text"
                ><div class="intelligent-filling-icon">
                  <img src="../assets/image/icon-tianbao.png" />
                </div>
                智能填报
              </span>
            </div>
          </button>
        </div>
      </div>
      <div class="nav-box">
        <div
          v-for="(item, index) in navList"
          :key="index"
          @click="handleClick(item)"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="img-list">
        <img src="../assets/image/index/img-yaoqinghaoyou@2x.png" />
        <img src="../assets/image/index/icon-shaitongzhishu@2x.png" />
        <img src="../assets/image/index/icon-xiangxijiaocheng@2x.png" />
      </div>
      <div class="kx">
        <div class="l">
          <img src="../assets/image/index/img-zuixinzixun@2x.png" />
          <div class="kx-div">
            <!-- <van-notice-bar :scrollable="false">
              <van-swipe
                vertical
                class="notice-swipe"
                :autoplay="3000"
                :show-indicators="false"
              >
                <van-swipe-item>高考数据卡测评卡可联系下方微信~</van-swipe-item>
                <van-swipe-item>紧急电话：13898688243</van-swipe-item>
              </van-swipe>
            </van-notice-bar> -->
            <van-notice-bar :scrollable="false">
              <van-swipe
                vertical
                class="notice-swipe"
                :autoplay="3000"
                :show-indicators="false"
              >
                <van-swipe-item
                  v-for="(item, index) in noticeList"
                  :key="index"
                >
                  {{ item.title }}</van-swipe-item
                >
              </van-swipe>
            </van-notice-bar>
          </div>
        </div>
        <div class="r">
          <img src="../assets/image/jinru.png" />
        </div>
      </div>
      <div style="padding:0px 15px 70px;">
        <img
          src="../assets/image/index/ditu.png"
          style="border-radius: 10px;
    width: 100%;"
          alt=""
          class="swipe-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      selectTag: '',
      piciInfo: null,
      zhiyuanNum: 0,
      navList: [
        {
          id: 1,
          img: require('@/assets/image/icon1.png'),
          name: '找大学',
        },
        {
          id: 2,
          img: require('@/assets/image/icon2.png'),
          name: '查专业',
        },
        {
          id: 3,
          img: require('@/assets/image/icon3.png'),
          name: '看职业',
        },
        {
          id: 4,
          img: require('@/assets/image/icon4.png'),
          name: '批次线',
        },
        {
          id: 5,
          img: require('@/assets/image/icon5.png'),
          name: '位次查询',
        },
      ],
      scoreHistory: null,
      userInfoData: null,
      noticeList: null,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    }),
  },
  watch: {
    userInfo: function(newVal) {
      // if(this.userInfo && !this.userInfo.point){
      //   this.getScoreHistory();
      // }
      // if (this.userInfo && !this.userInfo.province) {
      //   this.$router.push({ name: 'SelectCity' });
      // }
      if (
        newVal &&
        !this.piciInfo &&
        !this.userInfo.is_new &&
        this.userInfo.province &&
        this.userInfo.point
      ) {
        this.getScorePiciTuijian();
      }
      if (newVal && this.zhiyuanNum == 0) {
        this.getMyVolunteer();
      }
    },
    currentProvince: function(val) {
      console.log('home', val);
    },
  },
  mounted() {
    let info = JSON.parse(localStorage.getItem('userInfo')) || null;
    if (info && info.province) {
      this.getInfoData();
    }
    if (info && info.province && info.point) {
      if (!info.is_new) {
        this.getScorePiciTuijian();
        this.getMyVolunteer();
      } else {
        if (info.point && info.subject_str) {
          this.getMyVolunteer();
        } else {
          // this.$router.push({ path: '/fillScore/create' });
        }
      }
    }
    this.getNotice();
  },
  methods: {
    async getInfoData() {
      const apiUrl = '/api/user/info/';
      const response = await this.$axios.get(apiUrl);
      if (response.data.errno === 0) {
        console.log('====userInfoData', response.data);
        this.userInfoData = response.data.results;
        this.getScoreHistory();
      }
    },
    async getScoreHistory() {
      const apiUrl = '/php/user.score.get'; //'php/score.config.get'
      const res = await this.$axios.get(apiUrl);
      if (res.data.ret === 0) {
        this.scoreHistory = res.data.data;
      }
    },
    async getMyVolunteer() {
      const apiUrl = '/api/volunteer/';
      try {
        const response = await this.$axios.get(apiUrl);
        if (response.data.errno === 0) {
          this.zhiyuanNum = response.data.results.length;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    toZhiyuanList() {
      if (this.zhiyuanNum > 0) {
        this.$router.push({ name: 'MyTablesList' });
      } else {
        this.$toast('暂无志愿表');
      }
    },
    editScore() {
      this.$router.push({ path: '/fillScore/update' });
    },
    editPici() {
      this.$router.push({ path: '/changePici' });
    },
    async getScorePiciTuijian() {
      console.log('tuijian--', this.userInfo);
      const apiUrl = '/api/province/data/query/';
      let params = {
        province: this.userInfo && this.userInfo.province,
        point: this.userInfo && this.userInfo.point,
        type: (this.userInfo && this.userInfo.type) || '',
        gap_point: this.userInfo && this.userInfo.gap_point,
      };
      try {
        const res = await this.$axios.post(apiUrl, params);
        if (res && res.data.errno === 0) {
          const Data = res.data.results;
          // console.log("推荐批次", Data);
          localStorage.setItem('scoreInfoData', JSON.stringify(params));
          this.selectTag = Data.select_tag;
          this.piciInfo = Data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    simulatedFillTable() {
      if (this.userInfo && this.userInfo.point) {
        localStorage.removeItem('allSchoolData');
        localStorage.removeItem('localPage');
        localStorage.removeItem('localHasNext');
        localStorage.removeItem('localTotalPage');
        if (this.userInfo.is_new) {
          this.$router.push({
            name: 'SimulatedFill',
            params: { key: 'new' },
          });
        } else {
          this.$router.push({
            name: 'SimulatedFill',
            params: { key: this.selectTag },
          });
        }
      } else {
        this.$router.push({
          name: 'FillScore',
          params: { type: 'create' },
        });
      }
    },
    handleScore(num) {
      if (num == 1) {
        // this.$toast('加速更新中，敬请期待~')
        // if (true) return;
        // this.$router.push({ path: '/accounts/oneFilling' });
        if (this.scoreHistory?.score) {
          this.$router.push({
            path: '/accounts/oneFilling',
            query: {
              grade: this.scoreHistory.score.predict_score,
              course: this.scoreHistory.score.course,
            },
          });
        } else {
          this.$router.push({ path: '/accounts/createScore' });
        }
      }
      if (num == 2) {
        console.log('===this.scoreHistory', this.scoreHistory?.score);
        if (this.scoreHistory?.score) {
          this.$router.push({
            path: '/accounts/chooseBatch',
            query: {
              grade: this.scoreHistory.score?.predict_score,
              course: this.scoreHistory.score.course,
            },
          });
        } else {
          this.$router.push({ path: '/accounts/createScore' });
        }
      }
      if (num == 3) {
        console.log('===this.scoreHistory', this.scoreHistory);
        if (this.scoreHistory?.score) {
          this.$router.push({
            path: '/accounts/createScore',
            query: {
              grade: this.scoreHistory.score?.predict_score,
              course: this.scoreHistory.score.course,
            },
          });
        } else {
          this.$router.push({ path: '/accounts/createScore' });
        }
      }
    },
    closePopup() {
      this.$emit('closePopup', false);
    },
    handleHome(name, path) {
      this.closePopup();
      if (name == '模拟填报') {
        if (this.scoreHistory?.score) {
          this.$router.push({
            path: '/accounts/chooseBatch',
            query: {
              grade: this.scoreHistory.score.predict_score,
              course: this.scoreHistory.score.course,
            },
          });
        } else {
          this.$router.push({ path: '/accounts/createScore' });
        }
      } else {
        this.$router.push({ path: path });
      }
    },
    //列表跳转
    handleClick(item) {
      //找大学
      if (item.name == '找大学') {
        this.$router.push({ path: '/colleges/collegeList' });
      }
      //查专业
      if (item.name == '查专业') {
        this.$router.push({ path: '/majors/homepage' });
      }
      //看职业
      if (item.name == '看职业') {
        this.$router.push({ path: '/job/homepage' });
      }
      //批次线
      if (item.name == '批次线') {
        this.$router.push({ path: '/pcl/homepage' });
      }
      //位次查询
      if (item.name == '位次查询') {
        this.$router.push({ path: '/rankSearch/homepage' });
      }
    },
    //最新快讯
    async getNotice() {
      const apiUrl = '/api/index/message/'; //'php/score.config.get'
      const res = await this.$axios.get(apiUrl);
      console.log('====最新快讯', res);
      if (res.data.errno === 0) {
        this.noticeList = res.data.results.results;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home_page_index {
  min-height: 100vh;
  width: 100%;
  font-size: 0.24rem;
  background: #fff;

  .page_head {
    .banner_box {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .full-in-box {
    margin: -90px 15px 15px;
    height: 180px;
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .full-in-box .full-in-top-line {
    height: 56px;
    border-bottom: 1px solid #ebedf0;
    box-sizing: border-box;
    position: relative;
    line-height: 58px;
  }

  .full-in-box .full-in-top-line .full-in-input {
    height: 100%;
    color: #000;
    font-size: 22px;
    font-weight: 600;
    line-height: 43px;
    padding-left: 15px;
  }

  .full-in-box .full-in-top-line .full-in-icon {
    position: absolute;
    right: 18px;
    font-size: 20px;
    color: #999;
    top: 18px;
  }

  .full-in-box .full-in-top-line .users-study {
    display: flex;
    align-items: flex-end;
    padding-left: 15px;
  }

  .full-in-box .full-in-top-line .users-score {
    height: 40px;
    line-height: 40px;
    font-size: 40px;
    letter-spacing: -1px;
    display: inline-block;
    font-family: Bebas;
  }

  .full-in-box .full-in-top-line .users-course,
  .full-in-box .full-in-top-line .users-rank {
    color: #000;
    font-weight: 700;
    font-size: 14px;
    height: 14px;
    line-height: 14px;
    margin-left: 5px;
    display: inline-block;
  }

  .full-in-box .full-in-btn-group {
    display: flex;
    justify-content: space-between;
  }

  .full-in-box .full-in-btn-group .one-click-filling {
    width: 42.4%;
    height: 45px;
    line-height: 45px;
    margin-right: 1.6%;
  }

  .full-in-box .full-in-btn-group .one-click-filling.van-button--danger {
    background-color: #fbf5f5;
    border: 1px solid #910000;
    color: #910000;
  }

  .full-in-box .full-in-btn-group .intelligent-filling {
    width: 53%;
    height: 45px;
    line-height: 45px;
    border: none;
    // background: linear-gradient(180deg, #fa746c, #f73f33);
    background: linear-gradient(180deg, #910000, #f73f33);
  }

  .full-in-box .full-in-btn-group .intelligent-filling .van-button__text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .full-in-box
    .full-in-btn-group
    .intelligent-filling
    .intelligent-filling-icon {
    width: 22px;
    height: 22px;
    line-height: 22px;
    margin-right: 5px;
  }

  .full-in-box
    .full-in-btn-group
    .intelligent-filling
    .intelligent-filling-icon
    img {
    width: 100%;
    height: 100%;
  }

  .full-in-box .van-button {
    padding-left: 4%;
    padding-right: 4%;
    font-size: 16px;
    text-align: center;
  }
  .menu_box {
  }
  .area_box {
    .area_title {
      display: flex;
      margin: 10px 16px;
      line-height: 38px;
      height: 38px;
      color: #212121;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 800;
      .el-icon-arrow-left {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .area_ul {
      padding: 0 15px;
      height: 90vh;
      overflow: auto;
      li {
        border-bottom: 1px solid #f5f5f5;
        font-size: 15px;
        color: #212121;
        padding: 12px 0px;
        font-weight: 400;
      }
    }
  }
  //list
  .nav-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 15px;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px 10px 0;
    div {
      width: 20%;
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 5px;
        object-fit: cover;
      }
      span {
        display: block;
        text-align: center;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #333;
        font-weight: 600;
      }
    }
  }
}
.img-list {
  text-align: center;
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  img {
    width: 32%;
  }

  img:last-child {
    margin-right: 0;
  }
}
.kx {
  margin: 15px 15px;
  line-height: 40px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 5.66px 5.66px 30px 8px rgba(58, 60, 62, 0.14);
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .l {
    display: flex;
    align-items: center;
    width: 99vw;
    .kx-div {
      // white-space: nowrap;
      width: 64vw;
      /deep/ .van-notice-bar {
        color: #222;
        background: none;
        padding: 0;
      }
      /deep/ .van-notice-bar__content{
        position: static;
      }
    }
    /deep/ .notice-swipe {
      height: 24px !important;
      line-height: 24px !important;
    }

    img {
      height: 15px;
      margin-right: 10px;
    }
  }

  .r {
    img {
      width: 5px;
    }
  }
}
</style>
